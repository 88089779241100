@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

a{
    transition: .2s linear;
}

html, body{
    background: #fff;
    color: #222;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    height: 100%;
}


.wrapper{
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
}

header a, footer a, .social_block_media a{
    text-decoration: none;
    color: #fff;
}

input, select, button{
    font-family:inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
}

ul.root_item li {
    list-style: none;
}

ul.root_item li a {
    padding: 5px 0;
    line-height: 2;
    white-space: nowrap;
}

main{
    min-height: 300px;
}

main a, .main_nav a{
    text-decoration: none;
    color:#191F22;
}

main, .main_nav{
    color: #191F22;
}

.header_social{
    padding: 28px;
    margin-left: 6px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;

}

.social_icon{
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: .2s linear;
}


@media (hover: hover) and (pointer: fine) {
    .social_icon.youtube:hover{
        color:#D5001C;
    }
    
    .social_icon.vk:hover{
        color:#0075FD;
    }
    
    .social_icon.telegram:hover{
        color:#249CD8;
    }
}

  @media (hover: none) and (pointer: coarse) {
    .social_icon.youtube:active{
        color:#D5001C;
    }
    
    .social_icon.vk:active{
        color:#0075FD;
    }
    
    .social_icon.telegram:active{
        color:#249CD8;
    }
}  

header{
    background-color: #191F22;
    color: #fff;
    height: 80px;
    width: 100%;
    
}

.header_items{
    display: flex;
    flex-direction: row;
}

.header_addreses{
    display: flex;
    column-gap: 20px;
    margin-left: auto;
    padding: 30px;
    white-space: nowrap;
    & a{
        margin-left: -20px;
    }
    & div{
        display: flex;
        column-gap: 5px;
    }
}

.header_items{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header_items a{
    padding-left: 20px;
}

.header_nav{
    display: flex;
    padding: 30px;
    margin-left: 50px;
    font-size: 15px;
    font-weight:  300;
    white-space: nowrap;
}

.header_nav_item{
    transition: .2s linear;
    cursor: pointer;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: #D5001C;
          }
    }
      @media (hover: none) and (pointer: coarse) {
       &:active {
            color: #D5001C;
          }
        }
}

.header_items .get_call{
    border: 1px solid #fff;
    padding: 7px;
    height: 100%;
    font-weight: 400;
    font-size: 14px;
    margin-right: 15px;
    display: none;
}

footer{
    background-color: #191F22;
    color: #fff;
    margin-top: auto;
    padding-bottom: 50px;
}

.footer_main_content{
    display: flex;
    width: calc(100% - 40px);
    column-gap: 25px;
    row-gap: 25px;
    padding: 0 20px;
    max-width: 1564.5px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.footer_info{
    width: 100%;
    max-width: 228px;
    max-height: 385px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;
    padding-top: 48px;
    row-gap: 21px;
}

.footer_addreses{
    display: flex;
    flex-direction: column;
    font-size: 18px;
}

.footer_addreses span{
    font-size: 13px;
    font-weight: 300;
    color: #CACACA;
}

.footer_info > .get_call{
    border: solid 1px #fff;
    padding: 8px 15px 8px 15px;
    cursor: pointer;
    transition: .2s linear;
    font-weight: 300;
    font-size: 13px;
    color: #CACACA;
}


.footer_nav{
    display: flex;
    width: 100%;
    padding-top: 48px;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 50px;
    justify-content: space-between;
}

.footer_nav_item{
    max-width: 247px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.footer_nav_items_title, .footer_nav_items_title a{
    line-height: 19.2px;
    font-weight: 600;
    letter-spacing: 0.1em;
    padding-bottom: 6px;
}

.footer_nav_item > a{
    font-weight: 300;
    font-size: 13px;
    line-height: 18.4px;
    color: #CACACA;
    cursor: pointer;
    transition: .2s linear;
}

.main_nav{
    display: flex;
    background-color: #fff;;
    align-items: center;
    -webkit-align-items: center;
    column-gap: 25px;
    justify-content: center;
    user-select: none;; 
    & .mobile_nav_svg{
        transition: .2s linear;
        cursor: pointer;
        font-size: 50px;
        display: none;
        transition: .2s linear;
        &:hover{
            color: #D5001C;
        }
    }
    & a{
        font-size: 20px;
    }
    & .main_navigator{
        flex-direction: row;
        column-gap: 35px;
    }
}


nav{
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    justify-content: space-between;
    column-gap: 30px;
    margin-top: 15px;
}

nav a{
    font-size: 17px;
    line-height: 43px;
    font-weight: 400;
    color: #000;
    transition: .2s linear;
    padding-top: 15px;
    padding-bottom: 7px;
    white-space: nowrap
}

.nav_logo{
    background-image: url('./img/logo_white_solo.png');
    background-size: 40px 40px;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    float: left;

}

.nav_logo_container{
    display: flex;
    align-items: center;
    justify-content: center;
    & .nav_logo_text{
        margin:0 0 0 5px;
        font-weight: 500;
        font-size: 17px;
        text-wrap: nowrap;
    }
}

 nav > .get_call {
    border: solid 1px #CACACA;
    padding: 8px 15px 8px 15px;
    cursor: pointer;
    transition: .2s linear;
    text-wrap: none;
}


ul.root_item {
    display: none;
    position: absolute;
    padding: 10px 20px;
    margin: 0 0 0 -20px;
    z-index: 5;
    width: fit-content;
    border: 1px solid #CACACA;  
    background-color: #fff;
}   

nav > .pop_up {
    color: #fff;
}

.pop_up{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: transparent;
    z-index: 3;
    background-color: rgba(10,10,10, .8);
}

.pop_up_container{
    display: flex;
    width: 100%;
    height: 100%;
}


.pop_up_body{
    margin: auto;
    width: 320px;
    height: 470px;
    background-color: #fff;
    position: relative;
    padding: 30px;
}

.pop_up_logo{
    background-image: url('./img/logo_black_mini.png');
    background-size: 100px 100px;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    margin: auto;
}

.pop_up_close{
    font-size: 21px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 10px;
    color: #191F22;
    transition: 0.2s ease-in-out;
    &:hover{
        color: #D5001C;
    }
}

.get_call_form{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.pop_up_content > h3{
    margin-top: 20px;
    font-weight: 500;
    color: #191F22;
    text-align: center;
}

.getCall_input {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 280px;
    border-radius: 3px;
    overflow: hidden;
  }
  .getCall_input .span_label_name{
    position: absolute;
    top: -5px;
    left: 12px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
    pointer-events: none;
  }
  .getCall_input .focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
  }
  .getCall_input input {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 16px 12px 0 12px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    background: rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
    color: #000;
    transition: all 0.15s ease;
  }

  @media (hover: hover) and (pointer: fine) {
    .getCall_input input:hover {
        background: rgba(0, 0, 0, 0.04);
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
      }
}

  @media (hover: none) and (pointer: coarse) {
    .getCall_input input:active {
        background: rgba(0, 0, 0, 0.04);
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
      }
}  

  .getCall_input input:not(:-moz-placeholder-shown) + .span_label_name {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
  }
  .getCall_input input:not(:-ms-input-placeholder) + .span_label_name {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
  }
  .getCall_input input:not(:placeholder-shown) + .span_label_name {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
  }
  .getCall_input input:focus {
    background: rgba(0, 0, 0, 0.05);
    outline: none;
    box-shadow: inset 0 -2px 0 #D5001C;
  }
  .getCall_input input:focus + .span_label_name {
    color: #D5001C;
    transform: translate3d(0, -12px, 0) scale(0.75);
  }
  .inp input:focus + .span_label_name + .focus-bg {
    transform: scaleX(1);
    transition: all 0.1s ease;
  }

  #status_reqest_success{
    text-align: center;
    color: green;
    display: none;
    margin-top: -13px;
  }

  #status_reqest_fold{
    text-align: center;
    color: #D5001C;
    display: none;
    margin-top: -13px;
  }

  .button_send {
    width: 180px;
    height: 60px;
    position: absolute;
    left: 70px;
    top: 350px;
  }
  
  .button {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #D5001C;
    outline: none;
    transition: 0.5s ease-in-out;
  }
  
.button > svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 0.5s ease-in-out;
  }
  


  @media (hover: hover) and (pointer: fine) {
    .button:hover{
        transition: 0.5s ease-in-out;
        background: #D5001C;
      }
    
    
    .button:hover span {
        color: #fff;
        font-weight: 300;
    } 
      .button:hover svg {
        stroke-dashoffset: -480;
        
      }
}

  @media (hover: none) and (pointer: coarse) {
    .button:active{
        transition: 0.5s ease-in-out;
        background: #D5001C;
      }
    
    
    .button:active span {
        color: #fff;
        font-weight: 300;
    } 
      .button:active svg {
        stroke-dashoffset: -480;
        
      }
    }
  
  .button span {
    color: #D5001C;
    font-size: 18px;
    font-weight: 500;
  }  

  .swiper-button-prev:after, .swiper-container-rtl .swiper-button-prev:after {
    color: white;
    }

  swiper-slide{
    margin-top: 5px;
  }

  swiper-slide > a > img, swiper-slide > img{
    max-width: 100%;
    min-width: 0;
  }


.nav_main_page{
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 40px;
    margin: 20px;
}

.nav_item{
    border: solid 1px #CACACA;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
    width: 180px;
    transition: 0.2s ease-in-out;
    &:hover{
        border: solid 1px  #D5001C;
        color: #D5001C;
    }
}

.nav_printers_new img, .nav_filament img{
    height: 100px;
    width: 130px;
    margin-bottom: 10px;
}

.nav_3d_service img, .nav_spare_parts img{
    height: 100px;
    width: 110px;
    margin-bottom: 10px;
}


.nav_service img{
    height: 100px;
    width: 160px;
    margin-bottom: 10px;
}

.nav_tradeIn img{
    height: 100px;
    width: 80px;
    margin-bottom: 10px;
}

.about_block{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 200px;
    margin-top: 20px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    margin-inline: 150px;
}

.about_block_text{
    width: 40%;

}
.about_block_info_company{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 30px;
    font-weight: 400;
}

.about_block_logo img{
    height: 300px;
    width: 320px;

}

.about_block_title {
    color: #aba9a9;
    font-weight: 300;
    font-size: 18px;
}

.about_list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 30px;
    justify-content: center;
    column-gap: 50px;
    margin: 50px;
    padding: 20px;
    border: 1px solid #d2d2d2;
    align-items: center;
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    text-align: center;
}


.about_list_item{
    width: 30%;
    min-width: 300px;
    min-height: 180px;
    color:#191F22;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about_list_item_icon{
    color: #D5001C;
    font-size: 30px;
}

.about_list_item_title{
    font-size: 17px;
    font-weight: 700;
}

.about_list_item_paragraph{
    font-weight: 450;
    font-size: 14px;
    width: 300px;
}

.ymaps-2-1-79-map-copyrights-promo{
    display: none;
}

.yandex_map{
    position: relative;
    margin-top: auto;
}

.get_call_map{
    background-color: #fff;
    height: 240px;
    position: absolute;
    top: 100px;
    left: 100px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding:20px;
    border: 1px solid #191F22;
}

.get_call_map_svg{
    color: #D5001C;
    font-size: 22px;
}


.get_call_map_span{
    font-weight: 300;
    font-size: 17px;
    margin-left: 10px;
}

.get_call_map > .get_call{
    border: solid 1px #191F22;
    padding: 8px 15px 8px 15px;
    cursor: pointer;
    transition: .2s linear;
    font-size: 17px;
    font-weight: 400;
}

.get_call_map_title{
    font-weight: 500;
    font-size: 20px;
}

.main_catalog_prodcuts{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.sidebar{
    flex: 0 0 calc( 20% - 30px );
    margin: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    padding: 15px 0 15px 15px;
}

.sidebar_catalog{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25); 
}

.sidebar_catalog_duble_submenu{
    list-style: none;
}

.sidebar_catalog_printers_topmenu, .sidebar_catalog_filament, .sidebar_catalog_spareParts, .sidebar_catalog_toys{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding: 10px;
    height: 60px;
    align-items: center;
    transition: 0.2s ease-in-out;
    &:hover{
        color: #D5001C;
        transform: scale(1.03);
    }
}

.sidebar_catalog_topmenu_photo{
    float: left;
}

.sidebar_catalog_topmenu_tittle{
    justify-content: center;
    align-items: center;
}

.sidebar_catalog_printers_topmenu_photo img, .sidebar_catalog_filament_topmenu_photo img {
    height: 50px;
    width: 60px;
}

.sidebar_catalog_toys_topmenu_photo img{
    height: 30px;
    width: 60px;
}

.sidebar_catalog_spareParts_topmenu_photo img {
    height: 50px;
    width: 60px;
}

.sidebar_alngle_right{
   margin-left: auto;
    
}

.filter_main{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    padding: 10px;
}

.filter_title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    height: 40px;
    margin-bottom: 20px;
    >.filter_title_icon{
        font-size: 22px;
    }
}

.filter_second_title{
    cursor: pointer;
    border-bottom: 1px solid #d2d5d7;
    font-size: 15px;
    height: 30px;
    user-select: none;
    >.filter_second_title_icon{
        float: right;
        margin-right: 10px;
        transition: 0.2s ease-in-out;
    }
}

.filter_second_title_icon.true{
    transform: rotate(90deg);
}

.range_title{
    cursor: context-menu;
}

.range_slider{
    position: relative;
    width: 100%;
    height: 5px;
    margin: 30px 0;
    background-color: #4A4F53;
    user-select: none;
}

.slider_track{
    height: 100%;
    position: absolute;
    background-color: #D5001C;
}

.range_slider input {
    position: absolute;
    width: 100%;
    background: none;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    appearance: none;
}

input[type="range"]::-webkit-slider-thumb{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 3px solid #fff;
    background: #fff;
    pointer-events: auto;
    appearance: none;
    cursor: pointer;
    box-shadow: 0 .125rem .56rem -0.125rem rgba(0,0,0, .25);
}

input[type="range"]::-moz-range-thumb{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 3px solid #fff;
    background: #fff;
    pointer-events: auto;
    -moz-appearance: none;
    cursor: pointer;
    box-shadow: 0 .125rem .56rem -0.125rem rgba(0,0,0, .25);
}

.tooltip{
    padding: .25rem .5rem;
    border: 0;
    background: #4A4F53;
    color: #fff;
    font-size: .75rem;
    line-height: 1.2;
    border-radius: .25rem;
    bottom: 120%;
    display: block;
    position: absolute;
    text-align: center;
    white-space: nowrap;
}

.min_tooltip{
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    z-index: 1;
}

.max_tooltip{
    right: 50%;
    transform: translateX(50%) translateY(-100%);
}

.input_box{
    display: flex;
    width: 265px;
}

.min_box, .max_box{
    width: 50%;
}

.min_box{
    padding-right: .5rem;
    margin-right: .5rem;
}

.input_wrap{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input_addon{
    display: flex;
    align-items: center;
    padding: .625rem 1rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color:#4A4F53;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d4d7e5;
    border-radius: .25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input_field{
    margin-left: -1px;
    padding: .425rem .75rem;
    font-size: 0.8125rem;
    border-radius: .25rem;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 1%;
    color:#4A4F53;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #CACACA;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input_field:focus{
    outline: none;
}


@media (hover: hover) and (pointer: fine) {
    .main_nav_item > a:hover, .root_item li > a:hover{
        color: #D5001C;
    }
    
    .main_nav_3dservice:hover .dropdown_3dservice{
        display: block;
    }
    .hedear_pop_up:hover .dropdown_marketplaces{
        display: flex;
        color: #fff;
        font-size: 17px;
        column-gap: 10px;
        background-color: #191F22;
        border: none;
        & img{
            margin-top: 15px;
            cursor: pointer;
        }
    }
    .label_filter:hover{
        color:#D5001C;
        transition: 0.2s ease-in-out;
    }
}

  @media (hover: none) and (pointer: coarse) {
    .main_nav_item > a:active, .root_item li > a:active{
        color: #D5001C;
    }
    
    .main_nav_3dservice:active .dropdown_3dservice{
        display: block;
    }
    .label_filter:active{
        color:#D5001C;
        transition: 0.2s ease-in-out;
    }
  }

.filter_sidebar{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.label_filter{
    display: flex;
    cursor: pointer;
    user-select: none;
    column-gap: 10px;
    margin-left: 10px;
    
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'],
    input[type='radio'] {
      transition: 0.2s ease-in-out;
      --active: #D5001C;
      --active-inner: #fff;
      --focus: 2px rgba(231, 0, 0, 0.911);
      --border: #BBC1E1;
      --border-hover: #D5001C;
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
        appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }
      &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
      &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: .9;
        &:checked {
          --b: var(--disabled-inner);
          --bc: var(--border);
        }
        & + label {
          cursor: not-allowed;
        }
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
            &:not(:checked) {
              &:not(:disabled) {
                --bc: var(--border-hover);
              }
            }
          }
    }
      @media (hover: none) and (pointer: coarse) {
        &:active {
            &:not(:checked) {
              &:not(:disabled) {
                --bc: var(--border-hover);
              }
            }
          }
      }
    
      &:focus {
        box-shadow: 0 0 0 var(--focus);
      }
      &:not(.switch) {
        width: 21px;
        &:after {
          opacity: var(--o, 0);
        }
        &:checked {
          --o: 1;
        }
      }
      & + label {
        font-size: 14px;
        line-height: 21px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin-left: 4px;
      }
    }
    input[type='checkbox'] {
      &:not(.switch) {
        border-radius: 7px;
        &:after {
          width: 5px;
          height: 9px;
          border: 2px solid var(--active-inner);
          border-top: 0;
          border-left: 0;
          left: 7px;
          top: 4px;
          transform: rotate(var(--r, 20deg));
        }
        &:checked {
          --r: 43deg;
        }
      }
      &.switch {
        width: 38px;
        border-radius: 11px;
        &:after {
          left: 2px;
          top: 2px;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          background: var(--ab, var(--border));
          transform: translateX(var(--x, 0));
        }
        &:checked {
          --ab: var(--active-inner);
          --x: 17px;
        }
        &:disabled {
          &:not(:checked) {
            &:after {
              opacity: .6;
            }
          }
        }
      }
    }
    input[type='radio'] {
      border-radius: 50%;
      &:after {
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: var(--active-inner);
        opacity: 0;
        transform: scale(var(--s, .7));
      }
      &:checked {
        --s: .5;
      }
    }
  }

.product_catalog{
    display: flex;
    flex-direction: column;
}

.product_catalog_block{
    flex: 1 0 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
}

.product_item{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid #efeeee;
    border-radius: 10px;
    height: 340px;
    padding: 20px;
    flex: 0 0 calc( 26% - 30px );
    @media (hover: hover) and (pointer: fine) {
        &:hover{
            box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25); 
        }
    }
    
      @media (hover: none) and (pointer: coarse) {
        &:active{
            box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25); 
        }
      }
    
}

.product_catalog_price_status{
    display: flex;
    flex-direction: row;
}

.sidebar + .product_catalog .product_item {
    flex: 0 0 calc( 33.7% - 10px );
}

.product_catalog_photo{
    max-width: 50%;
    height: auto;
}

.product_catalog_div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 55%;
    min-width: 280px;
    margin-inline: auto;
    margin-bottom: auto;
}

.product_catalog_name{
    font-size: 17px;
    margin-bottom: 5px;
}

.product_catalog_status{
    display: flex;
    font-size: 13px;
    color: #D5001C;
    margin-left: auto;
    margin-top: 10px;
}

.product_catalog_typePrinter{
    font-size: 13px;
    color: #555454;
    margin-top: 10px;
}

.product_catalog_price{
    font-size: 20px;
    color: #191F22;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #191F22;
}

.product_page_main{
    display: flex;
    margin-inline: 100px;
    flex-wrap: wrap;
}

.swiper_product{
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    width: 45%;
    min-width: 45%;

}

.product_specifications{
    flex-basis: auto;
    margin-left: 30px;
    flex-wrap: wrap;
}

.product_specifications_url{
    cursor: pointer;
}
.product_specification_write_messanger{
    font-size: 13px;
    color: #969696;
    text-align: center;
    margin-block: 10px;
}

.icons_messanger{
    display: flex;
    column-gap: 10px;
    font-size: 30px;
    justify-content: center;
    color: #191F22;
    cursor: pointer;
    >.icons_whatsapp{
        transition: 0.13s ease-in-out;
    }
    >.icons_telegram{
        transition: 0.13s ease-in-out;
    }
}

.product_specifications_block{
    margin-block: 30px;
    display: flex;
    column-gap: 15px;
    font-weight: 400;

    >.product_specifications_name_block{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        font-size: 13px;
        color: #969696;;
    }
    >.product_specifications_value_block{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        font-size: 13px;
        >.product_specifications_forPrinter{
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            row-gap: 10psx;
        }
    }
    >.product_specifications_defis_block{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        font-size: 13px;
        color: #aba9a9;
    }
}

.product_specifications_buy_block{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
    width: 280px;
    padding-inline: 30px;
    padding-block: 10px;
    >.product_specifications_status{
        color: #D5001C;
        font-size: 14px;
        margin-bottom: 10px;
    }
    >.product_specifications_price{
        font-size: 50px;
        font-weight: 300;
    }
}
.product_specifications_buy_block > .get_call{
    margin: auto;
    border: solid 1px #191F22;
    padding: 8px 15px 8px 15px;
    cursor: pointer;
    transition: .2s linear;
    margin-top: 5px;
    width: 80%;
    font-size: 14px;
    text-align: center;
}

.product_specifications_name{
    font-size: 30px;
    margin-top: 50px;
    width: 500px;
}

.product_specifications_ozon_url, .product_specifications_wb_url, .product_specifications_yandex_url{
    cursor: pointer;
}



.product_specification_mp_icons{
    display: flex;
    column-gap: 5px;
    margin-top: 5px;
    > .product_specifications_wb_url{
        min-width: 50px;
        min-height: 63px;
        transition: 0.2s ease-in-out;
    }
    > .product_specifications_ozon_url{
        min-width: 60px;
        min-height: 63px;
        transition: 0.2s ease-in-out;
        margin-top: -3px;
    }
    > .product_specifications_yandex_url{
        min-width: 50px;
        min-height: 63px;
        transition: 0.13s ease-in-out;
    }
}

.social_block{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    background-color: #191F22;
    color:#fff;
    justify-content: center;
    padding: 100px;
    align-items: center;
    row-gap: 100px;
    column-gap: 200px;
    text-align: center;
    
}

.social_block_media{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    font-weight: 500;
    font-size: 15px;
    & .header_social{
       margin: 0;
       padding: 0;
    }
}

.social_block_span{
    font-size: 13px;
    font-weight: 300;
}

.social_block_media > .icons_messanger{
    color: #fff;
}

.social_block_logo_sindicat > img{
    width: 100%;
    height: auto;
}

.getCall_productCatalog{
    border: solid 1px #191F22;
    padding: 8px 15px 8px 15px;
    color: red;
}


.product_variation_block{
    margin-block: 20px;
    width: 500px;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: center;
    
}

.product_variation_subBlock{
    display: flex;
    flex-direction: column;
    width: 150px;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .2s linear;
    user-select: none;
    border: #eeecec 1px solid;
    @media (hover: hover) and (pointer: fine) {
        &:hover{
            background-color: #D5001C;
            color: #fff;
            border: 1px #D5001C solid;
        }
    }
    
      @media (hover: none) and (pointer: coarse) {
        &:active{
            background-color: #D5001C;
            color: #fff;
            border: 1px #D5001C solid;
        }
      }
}

.filter_reset{
    background-color: #fff;
    border: solid 1px #191F22;
    padding: 10px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    color: #000;
    @media (hover: hover) and (pointer: fine) {
        &:hover{
            color: #fff;
            background-color: #D5001C;
            border: 1px solid #D5001C;
        }
    }
      @media (hover: none) and (pointer: coarse) {
        &:active{
            color: #fff;
            background-color: #D5001C;
            border: 1px solid #D5001C;
        }
      }
}

.hide_filter{
    float: right;
    font-size: 30px;
    margin-top: -15px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
        user-select: none;
}

.hide_filter.true{
    transform: rotate(-90deg);
}

.product_variation_item{
    padding: 3px;
    user-select: none;
    width: 100%;
}

@media (hover: hover) and (pointer: fine) {
    .get_call:hover{
        background-color: #D5001C ;
        border: solid 1px #D5001C;
        font-weight: 500;
        color: #F0E8E8;
    }
    .footer_nav_item > a:hover{
        color: #D5001C;
    }
    .header_nav > a:hover{
        color: #D5001C;
    }
    .product_specifications_wb_url >img:hover{
        height: 50px;
    }
    .product_specifications_yandex_url>img:hover,
    .product_specifications_ozon_url>img:hover{
    height: 50px;
    }
    .pages_slider_item:hover{
        background-color: #D5001C;
        color: #fff;
        border: 1px solid #dcdcdc;
    }
    .icons_whatsapp:hover{
        color: #25D366;
    }
    
    .icons_telegram:hover{
        color: #2198D3;
    }
}

  @media (hover: none) and (pointer: coarse) {
    .get_call.active{
        background-color: #D5001C ;
        border: solid 1px #D5001C;
        font-weight: 500;
        color: #F0E8E8;
    }
    .footer_nav_item > a:active{
        color: #D5001C;
    }
    .header_nav > a:active{
        color: #D5001C;
    }
    .product_specifications_wb_url >img:active{
        height: 50px;
    }
    .product_specifications_yandex_url>img:active,
    .product_specifications_ozon_url>img:active{
    height: 60px;
    }
    .pages_slider_item:active{
    background-color: #D5001C;
    color: #fff;
    border: 1px solid #dcdcdc;
    }
    .icons_whatsapp:active{
        color: #25D366;
    }
    
    .icons_telegram:active{
        color: #2198D3;
    }
  }


.pages_slider_block{
    display: flex;
    flex-direction: row;
    height: 100%;
    column-gap: 10px;
    justify-content: center;
    margin-block: 30px;
}

.pages_slider_item{
    border: 1px solid #191F22;
    padding:10px;
    user-select: none;
    cursor: pointer;
    transition: .2s linear;
}

.pages_slider_item.true{
    background-color: #D5001C;
    color: #fff;
    border: 1px solid #dcdcdc;
}

.filter_head{
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
}


.service_nav{
    display: flex; 
    flex-direction: row;
    margin-inline: 150px;
    margin-block: 30px;
    flex-wrap: wrap;
    row-gap: 50px;
    width: 80%;
    justify-content: space-between;
}

.service_nav_item{
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #CACACA;
    width: 25%;
    min-width: 300px;
    text-align: center;
    padding-top: 12px;
    transition: .2s linear;
    user-select: none;
    align-items: center;
    & a{
        color: #fff;
    }
    & .scanning_img{
        height: 280px;
        pointer-events: none;
    }
    & .modeling_img{
        height: 280px;
        pointer-events: none;
    }
    & .printing_img{
        height: 280px;
        pointer-events: none;
    }
    @media (hover: hover) and (pointer: fine) {
        &:hover{
            border: 1px solid #D5001C;
            & span{
                color: #D5001C;
            }
        }
    }
      @media (hover: none) and (pointer: coarse) {
        &:active{
            border: 1px solid #D5001C;
            & span{
                color: #D5001C;
            }
        }
    }
}

.servcice_nav_item_button{
    margin: 10px 40px 0px 40px;
    padding: 10px 0px 10px 0px;
    border: 1px solid #CACACA;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    transition: .2s linear;
}

.servcice_nav_item_text{
    font-size: 14px;
    font-weight: 300;
    padding: 10px;
}

.main_mobile_navigator_shell{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: transparent;
    z-index: 2;
    background-color: rgba(10,10,10, .8);
}

.mobile_navigator{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
    background-color: #fff;
    width: 320px;
    & .second_mobile_navigator{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        transition: .2s linear;
    }
    .mobile_menu_svg{
        display: block;
        & #svg_back_menu{
            margin-top: 3px;
            font-size: 40px;
            float: left;
            display: none;
        }
        & #svg_close_menu{
            float: right;
            margin-right: 10px;
        }
     }
}

#svg_close_menu{
    display: flex;
}

#service_menu{
    display: none;
}

.main_mobile_item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 30px;
    font-weight: 600;
    font-size: 25px;
    transition: .2s linear;
    cursor: pointer;
    color: #000;
    width: 100%;

    & .main_mobile_item_span{
        font-weight: 600;
        font-size: 22px;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover{
            & svg{
                opacity: 1;
            }
        }
        &:hover{
            color: #D5001C;
        }
        & a:hover{
            color: #D5001C;
        }
    }
    
      @media (hover: none) and (pointer: coarse) {
        &:active{
            & svg{
                opacity: 1;
            }
        }
        &:active{
            color: #D5001C;
        }
        & a:active{
            color: #D5001C;
        }
    }   
}

.hedear_pop_up span{
    cursor: pointer;
    transition: .2s linear;
    @media (hover: hover) and (pointer: fine) {
        &:hover{
            color: #D5001C;
        }
    }
}

.mini_mobile_nav{
    display: flex;
    flex-direction: column;
    margin-inline: 20px;
    padding: 10px;
    margin-top: auto;
    border-top: 1px solid #CACACA;
    & span{
        font-size: 18px;
    }
}

.mobile_nav_marketplaces{
    display: flex;
    column-gap: 10px;
    margin-left: 30px;
    margin-top: auto;
}

.service_main_content{
    display: flex;
    flex-direction: column;
    & .service_main_photo img{
        width: 100%;
    }
}
.service_history{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    column-gap: 100px;
    row-gap: 50px;
    justify-content: space-between;
    margin-inline: 60px;
}



.service_history_title{
    color: #CACACA;
    margin-left: 60px;  
    margin-bottom: 20px;
}

.service_history_item{
    width: 300px;
    padding: 20px;
    border: 1px solid #CACACA;
    font-size: 15px;
    font-weight: 400;
}

.service_history_photo{
    width: 100%;
    margin-bottom: 20px;
}

.service_text_list{
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    row-gap: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 17px;
    font-weight: 400;
    margin-block: 20px;
    & ul{
        width: 320px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        list-style: none;
        border: 1px solid #191f22;
        padding: 20px;
    & li{
            border-bottom: 1px solid #CACACA;
        }
    & h2{
        font-weight: 500;
        display: flex;
        align-items: center;
        font-size: 18px;
        & svg{
            color: #D5001C;
            margin-right: 10px ;
            font-size: 30px;
        }
    }

    }
}

.service_main_block{
    display: flex;
    margin: 50px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 100px;
    & .service_main_block_photo{
        display: flex;
        align-items: center;
        & img{
            width: 600px;
        }
    }
    & .service_main_block_text{
        width: 700px;
        margin-block: 50px;
        & p{
            font-weight: 400;
        }
        & span{
            color: #CACACA;
            font-size: 20px;
            font-weight: 400;
        }
    }
}

.three_service_main{
    padding-top: 50px;
    background-color: #191F22;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.three_service_title{
    margin-top: 50px;
    margin-bottom: 20px;
}

.three_service_text{
    width: 80%;
    font-size: 16px;
    font-weight: 400;
}

.modeling_head{
    display: flex;
    justify-content: center;
    margin-inline: 100px;
    text-align: start;
    column-gap: 50px;
    row-gap: 50px;
}

.modeling_head_img{
    padding: 10px;
    border: 1px solid #cacaca;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    & img{
        width: 500px;
        padding-bottom: 10px;
    }
    & .modeling_head_img_alt{
        border-top: 1px solid #cacaca;
        padding-top: 10px;
    }
}

.modeling_head_text{
    & .get_call{
        text-align: center;
        width: 200px;
        padding: 10px;
        background-color: #D5001C;
        margin-top: 50px;
        cursor: pointer;
    }
}

.modeling_head_text_title{
    font-weight: 600;
    font-size: 20px;
    padding: 10px 15px 10px 15px;
    border: 1px solid #cacaca;
    width: 245px;
    margin-block: 20px;
}

.modeling_head_text_h2{
    margin-top: 20px;
}

.modeling_head_text_p{
    font-size: 15px;
    font-weight: 400;
}

.galary_service_photo{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
}

.galary_service_photo_item{
    width: 500px;
}

.galary_service_title{
    font-weight: 400;
    margin-top: 50px;
    margin-bottom: 20px;
}

.swiper_service{
    display: flex;
    max-width: 100%;
    & img{
        max-width: 600px;
        padding: 20px;
        border: 1px solid #cacaca;
    }
}

swiper-container{
    max-width: 100%;
}

swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    column-gap: 50px
}

.service_galary_title{
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: 400;
}

.tradeIt_page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & h2{
        display: flex;
        align-items: center;
        margin-block: 30px;
        font-weight: 400;
        font-size: 30px;
        & svg{
            color: #D5001C;
            margin-inline: 5px;
        }
    }
}

.tradeIn_head_text{
    width: 80%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    & div > svg{
        color: #D5001C;
        font-size: 12px;
    }
    & h3{
        margin-bottom: 20px;
        font-size: 19px;
        display: flex;
        align-items: center;
        & svg {
            color: #D5001C;
            font-size: 30px;
        }
    }
}
.tradeIn_way{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 50px;
    font-size: 14px;
    margin-block: 50px;
}

.tradeIn_way_item{
    width: 300px;
    margin-block: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & img{
        width: 200px;
    }
    & h2{
        color: #D5001C;
        font-size: 40px;
        margin-block: 0px;
    }
    & h3{
        font-weight: 500;
        font-size: 21px;
    }
    & p{
        color: #868686;
        font-weight: 500;
    }
}

.tradeIn_main_text{
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    & h3{
        margin-block: 20px;
        display: flex;
        align-items: center;
        margin-top: 70px;
        & svg {
            color: #D5001C;
            font-size: 30px;
            margin-inline: 5px;
        }
    }
}

.tradeIn_main_text_item{
    border: 1px solid #cacaca;
    padding: 15px;
    font-size: 14px;
}


.tradeIn_main_text_end{
    border-top: 1px solid #cacaca;
}

.tradeInHeadImg{
    width: 100%;;
}


@media (max-width: 1450px){

    .product_catalog_div{
        width: 90%;
    }
    .main_nav_item a{
        font-size: 18px;
    }

    .service_nav{
        justify-content: center;
        column-gap: 50px;
    }

    nav{
        column-gap: 15px;
        font-size: 13px;
    }
    .about_block_logo img{
        height: 200px;
        width: 200px;
    }

    .about_block{
        margin-inline: 30px;
    }
}

@media (max-width: 1300px){

    .modeling_head{
        flex-wrap: wrap;
    }

    .swiper_product{
        width: 100%;
    }
    .product_page_main{
        align-items: center;
        justify-content: center;
    }
    .service_text_list{
        & ul li{
            row-gap: 10px;
            font-size: 14px;
        }
    }
}

@media (max-width: 1150px){

    .about_block_text{
        width: 100%;
        font-size: 14px;
    }

    .swiper_service{
        & img{
            max-width: 600px;
            padding: 10px;
            width: 80%;
        }
    }
    
    swiper-slide{
        column-gap: 10px;
        flex-direction: column;
    }

    .header_nav{
        display: none;
    }
    .header_addreses{
        font-size: 13px;
    }
    .header_social{
        padding-right: 10px;
        column-gap: 10px;
        margin-left: -10px;
    }

    nav{
        column-gap: 15px;
        font-size: 14px;
    }
    .main_nav_item a{
        font-size: 14px;
    }
    .main_nav .get_call{
        display: none;
    }
    footer {
        padding-bottom: 50px;
    }
    #text_list_one{
        width: 270px;
        & h2{
            font-size: 15px;
            & svg{
                font-size: 20px;
            }
        }
    }
    #text_list_two{
        width: 270px;
        & h2{
            font-size: 15px;
            & svg{
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 950px){
    .service_history{
        justify-content: center;
        margin-inline: 20px;
    }
 
    .header_addreses{
        display: none;
    }
    .main_navigator{
        display: none;
    }

    .header_items .get_call{
        display: block;
        font-size: 12px;
        padding: 7px;
        font-weight: 300;
    }

    .header_items{
        margin-top: 15px;
    }

    .nav_logo{
        background-size: 40px 40px;
        width: 40px;
        height: 40px;

    }
    .nav_logo_container .nav_logo_text{
        font-size: 12px;
    }  
    
    .main_nav{
        justify-content: space-between;
        height: 40px;
        border-bottom: 1px solid #CACACA;
        position: sticky; 
        top: 0;
        z-index: 2;
        & .mobile_nav_svg{
            display: flex;
        }
    }

    .footer_main_content {
        width: calc(100% - 40px);
    }
    .get_call_map{
        top: 10px;
        left: 10px;
        height: 150px;
        padding: 10px;
        & .get_call_map_title{
            display: none;
        }
        & .get_call_map_span{
            font-size: 14px;
        }
        & .get_call_map_svg{
            font-size: 16px;
        }
        & .get_call{
            padding: 3px 5px 3px 5px;
            font-size: 12px;
            text-align: center;
        }
    }
    
    .social_block{
        padding: 50px;
    }

    .footer_main_content{
        padding: 0;
    }

    .footer_addreses a{
        font-size: 14px;
    }

    .about_list{
        margin:20px;
        & .about_list_item_paragraph{
            width: 230px;
            font-size: 13px;
        }
        & .about_list_item_title{
            font-size: 14px;
        }
    }

    .footer_info > .get_call{
        padding: 10px 5px 10px 5px;
        font-size: 12px;
    }

    .pages_slider_block{
        column-gap: 6px;
        margin-block: 30px;
        .pages_slider_item{
            padding: 4px;
            font-size: 13px;
        }
    }

    .sidebar{
        padding: 0px;
    }

}
@media (max-width: 700px){

    .tradeIt_page{
        & h2{
            font-size: 25px;
            margin-block: 50px;
        }
        .tradeIn_head_text{
            width: 95%;
            & h3{
                font-size: 15px;
                & svg {
                    font-size: 40px;
                }
            }
        }
        .tradeIn_main_text{
            width: 95%;
            & h3{
                font-size: 15px;
                & svg{
                    font-size: 50px;
                }
            }
        }
    }

    .modeling_head{
        & .modeling_head_text{
            width:90%;
        }
        & .modeling_head_img{
            & img{
                width: 290px;
            }
        }
    }
    .galary_service_photo_item{
            width: 300px;
        }
    
    #service_main_block_photo{
        width: 300px;
    }
    .main_catalog_prodcuts{
        flex-wrap: wrap;
        justify-content: center;
    }
    .product_specifications_block{
        flex-wrap: wrap;
        text-align: center;
    }
    .product_specifications_name{
        font-size: 20px;
        margin-top: 10px;
        width: 300px;
    }
    .product_specifications_buy_block{
        margin-top: 30px;
        margin-left: 0px;
        width: 250px;
        >.product_specifications_status{
            color: #D5001C;
            font-size: 12px;
            margin-bottom: 10px;
        }
        >.product_specifications_price{
            font-size: 35px;
            font-weight: 300;
            
        }
        & > .get_call{
            text-align: center;
            width: 90%;
            font-size: 13px;
        }
    }
    .product_page_main{
        margin-inline: 30px;
    }
    .product_specifications_block{
        justify-content: center;
        align-items: center;
    }
    .product_specifications{
        margin-left: 0px;
        text-align: center;
    }
    .product_specification_mp_icons{
        justify-content: center;
    }
    .product_variation_block{
        width: 100%;
        font-size: 13px;    
    }
    .product_variation_subBlock{
        width: 140px;
        padding: 5px;
    }
    .tradeIn_way_item{
        width: 300px;
        margin-block: 0px;
        & h2{
            font-size: 40px;
            margin-block: 0px;
            margin-top: -30px;
        }
        & h3{
            font-weight: 500;
            font-size: 21px;
        }
        & p{
            color: #868686;
            font-weight: 500;
        }
    }
}
